import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import "./layout.css"

import Logo from "../images/hackLogo.png"

const Header = ({ siteTitle }) => {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/ueq2cmo.css" />
      </Helmet>
      <FooterWrapper>
        <FooterCol>
          <h4>Content</h4>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <Link to="/resources">Resources</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
          </ul>
        </FooterCol>
        <FooterCol>
          <h4>Altamont</h4>
          <ul>
            <li>
              <a href="https://www.altamontschool.org" target="_blank">
                Our School
              </a>
            </li>
          </ul>
        </FooterCol>
        <FooterCol>
          <p className="text_small" style={{ marginTop: 44 }}>
            Created by Rob Dominguez and Ryan James, two CS teachers at The
            Altamont School in Birmingham, Alabama. Drop us a line and let us
            know how we can help you!
          </p>
          <img src={Logo} alt="Hack Altamont logo" />
        </FooterCol>
      </FooterWrapper>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

// Styled components:
const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 3fr;
  background: var(--grey-300);
  color: var(--white);
  font-size: 1.2rem;
  min-height: 40vh;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    justify-items: start;
    ul {
      padding-left: 0;
      li {
        margin-bottom: 10px;
      }
    }
  }

  img {
    max-width: 200px;
    height: auto;
  }

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
    }

    a {
      text-decoration: none;
      color: var(--white);
      transition: ease-in-out 0.3s;
      padding-bottom: 2px;
      border-bottom: solid 3px transparent;
    }

    a:hover {
      color: var(--teal);
      border-bottom: solid 3px var(--teal);
      cursor: pointer;
    }
  }
`

const FooterCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 50px;
  padding-right: 50px;

  h4 {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 100;
  }

  ul {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    font-size: 16px;
    list-style: none;

    li {
      margin-bottom: 10px;
    }
  }
`

export default Header
