import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import "./layout.css"

import Logo from "../images/hackLogo.png"

const Header = ({ siteTitle }) => {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/ueq2cmo.css" />
      </Helmet>
      <HeaderWrapper>
        <Link>
          <img src={Logo} alt="Logo" />
        </Link>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <Link to="/resources">Resources</Link>
          </li>
          {/* <li>
            <Link to="/">Tutorials</Link>
          </li> */}
          <li>
            <Link to="/about">About Us</Link>
          </li>
        </ul>
      </HeaderWrapper>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

// Styled components:
const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  background: var(--blue);
  color: var(--white);
  font-size: 1.2rem;
  min-height: 10vh;
  /* margin-bottom: 50px; */

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    justify-items: center;
    ul {
      padding-left: 0;
      li {
        margin-bottom: 10px;
      }
    }
  }

  img {
    max-width: 200px;
    height: auto;
    margin: 20px 20px;
  }
  ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    place-items: center center;
    list-style: none;

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
    }

    a {
      text-decoration: none;
      color: var(--white);
      transition: ease-in-out 0.3s;
      padding-bottom: 6px;
      border-bottom: solid 3px transparent;
    }

    a:hover {
      color: var(--teal);
      border-bottom: solid 3px var(--teal);
    }
  }
`

export default Header
